import axios from "axios";
import base from "./base";
import { Toast } from "vant";
//  cor 85
var instance = axios.create({
  baseURL: base,
  timeout: 65000,
});

instance.interceptors.request.use(
  function (config) {
    config.headers = Object.assign(config.headers, {
      tokenid: localStorage.getItem('HWTOKEN')
    })
    // console.log("config", config);
    // config.headers = Object.assign({
    //   "Access-Control-Allow-Origin": "*",
    // });
    if (!config.clear) {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中...",
      });
    }
    // 需要对get请求发送的数据去做处理
    if (config.method.toLowerCase() == "get") {
      config.params = config.data;
    }
    // regorg   安丘区划(370784) 奎文区划(370705)
    // useto   卫生许可新增(WSXKZX)  劳务派遣变更(LWPQBG)
    // uniqueId  唯一ID
    if (config.type == "codeUrl" || config.type == "mail") {
      // config.baseURL = "http://112.243.253.8:8280/prod-api/enjoy/pass";
      config.baseURL = "https://zw.zwfwpt.cn/diving-api/enjoy/pass";
    }
    if (config.type == "BH") {
      // 代理人授权码 通用
      // config.baseURL = "http://112.243.253.8:8081/prod-api/enjoy/pass";
      config.baseURL = "https://zw.zwfwpt.cn/prod-api/enjoy/pass";
    }
    // if (config.type == "mail") {
    if (config.type == "codemail") {
      // config.baseURL = 'http://192.168.1.121:8280/enjoy/pass'  // 测试
      config.baseURL = "https://zw.zwfwpt.cn/code-api"; // 正式
    }
    if (config.type == "LC") {
      config.baseURL = "https://zw.zwfwpt.cn/license-api";
    }
    // if (config.type == "pdf") {
    //   config.baseURL = "http://112.243.253.8:8081/license-api/enjoy/pass";
    // }
    // if (config.type == "kw") {
    //   // 奎文公共卫生
    //   config.baseURL = "http://112.243.253.8:8081/kwprod-api/enjoy/pass";
    // }
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    Toast.fail(error);
    return Promise.reject(error);
  }
);

// 数据响应之后执行的操作
instance.interceptors.response.use(
  function (response) {
    // if (response.data.code != 200) {
    //     Toast.fail(response.data.msg);
    // }
    Toast.clear();
    // 对响应数据做点什么
    return response.data;
  },
  function (error) {
    // 对响应错误做点什么
    Toast.fail(error);
    return Promise.reject(error);
  }
);

export default instance;
